import ivclass from '@invideoio/web-shared/Modules/IVClass';

export const BoostCounter = ({
  value,
  incrementDisabled,
  decrementDisabled,
  onDecrementClick,
  onIncrementClick,
  quota,
}: {
  value: number;
  incrementDisabled: boolean;
  decrementDisabled: boolean;
  onIncrementClick: () => void;
  onDecrementClick: () => void;
  quota: {
    premium_minutes: number;
    premium_media: number;
  };
}) => {
  return (
    <div
      className={ivclass(
        'iv-flex',
        'iv-pt-[12px] iv-pr-[16px] iv-pb-[12px] iv-pl-[12px]',
        'iv-items-center',
        'iv-justify-between',
        'iv-rounded-[12px]',
      )}
    >
      <div
        className={ivclass(
          'iv-flex',
          'iv-flex-col',
          'iv-items-start',
          'iv-gap-2',
        )}
      >
        <p className={'iv-text-body-xl iv-font-medium'}>{value}x Boost</p>
        <p
          className={
            'iv-text-body-l iv-leading-[18px] iv-font-medium iv-opacity-70'
          }
        >
          {quota.premium_minutes * value} AI Min
        </p>
        <p
          className={
            'iv-text-body-l iv-leading-[18px] iv-font-medium iv-opacity-70'
          }
        >
          {quota.premium_media * value} iStocks
        </p>
      </div>
      <div className="iv-flex iv-items-center iv-gap-8 iv-rounded-[8px]">
        <button
          disabled={decrementDisabled}
          onClick={onDecrementClick}
          className="iv-flex iv-justify-center iv-items-center disabled:iv-opacity-50 disabled:iv-cursor-not-allowed"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="16"
              cy="16"
              r="11.5"
              stroke="black"
              strokeOpacity={decrementDisabled ? '0.48' : '0.87'}
            />
            <g clipPath="url(#clip0_1687_50967)">
              <path
                d="M10.168 16H21.8346"
                stroke="black"
                strokeOpacity={decrementDisabled ? '0.48' : '0.87'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1687_50967">
                <rect
                  width="20"
                  height="20"
                  fill="black"
                  transform="translate(6 6)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button
          disabled={incrementDisabled}
          onClick={onIncrementClick}
          className="iv-flex iv-justify-center iv-items-center disabled:iv-opacity-50 disabled:iv-cursor-not-allowed"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="16"
              cy="16"
              r="11.5"
              stroke="black"
              strokeOpacity={incrementDisabled ? '0.48' : '0.87'}
            />
            <g clipPath="url(#clip0_1687_50972)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 10.167C17 9.61471 16.5523 9.16699 16 9.16699C15.4477 9.16699 15 9.61471 15 10.167V15H10.168C9.61568 15 9.16797 15.4477 9.16797 16C9.16797 16.5523 9.61568 17 10.168 17H15V21.8337C15 22.3859 15.4477 22.8337 16 22.8337C16.5523 22.8337 17 22.3859 17 21.8337V17H21.8346C22.3869 17 22.8346 16.5523 22.8346 16C22.8346 15.4477 22.3869 15 21.8346 15H17V10.167Z"
                fill="black"
                fillOpacity={incrementDisabled ? '0.48' : '0.87'}
              />
            </g>
            <defs>
              <clipPath id="clip0_1687_50972">
                <rect
                  width="20"
                  height="20"
                  fill="black"
                  transform="translate(6 6)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </div>
  );
};
